<template>
    <el-form-item :error="errorMessage">
        <el-select
            reserve-keyword
            :placeholder="placeholder"
            @change="handleChange"
            v-model="value"
            v-bind="$attrs"
            clearable
            :disabled="isDisabled"
        >
            <el-option
                v-for="option in options"
                :key="option.id"
                :label="option.name"
                :value="option.id"
            />
        </el-select>
    </el-form-item>
</template>

<script>
import { computed, defineComponent, ref, toRefs, watch } from 'vue';
import { useField } from "vee-validate";

export default defineComponent({
    name: "select-option",
    props: {
        name: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: () =>  'Seleccione una opción'
        },
        options: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: () => false
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: () => false
        },
        default: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    setup(props, context) {
        let isLoading = ref(false);
        const { disabled, options } = toRefs(props);
        const { value, errorMessage } = useField(props.name);

        const isDisabled = computed(() => {
            return disabled.value;
        });

        const combopOptions = computed(() => { return options.value; });

        watch(
            () => options.value,
            (newOptions) => {
                if (props.default && newOptions.length > 0 && !value.value) {
                    value.value = newOptions[0].id;
                }
            },
            { immediate: true }
        );

        const handleChange = () => {
            context.emit("changeSelectValue", value.value)
        }

        return {
            isLoading,
            value,
            errorMessage,
            isDisabled,
            combopOptions,
            handleChange
        }
    },
})
</script>

<style lang="scss">
    // .el-select {
    //     width: 100%;
    // }

    // .fullWidth {
    //     width: 510px;
    // }

</style>
